/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.active,
.not-active {
  text-transform: capitalize;
  width: 64px;
  height: 16px;
  color: #00ab44;
}

.not-active {
  color: #eb0a4ddc;
}

/* .facility-overflow__menu {
  background-color: '#f4f4f4';
}

.facility-overflow__menu:hover {
  background-color: '#ffffff';
  color: black;
}

.facility-overflow__menu li:hover {
  -webkit-box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px 2px rgba(0, 0, 0, 0.05);
  color: rgb(4, 46, 46);
  background-color: '#ffffff';
} */

.facility-personnel__others {
  /* style the p */
}

.facility-personnel__notaval {
  /* style the not availae personnel */
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  /* background-color: rgba(225, 234, 243, 0.22); */
  background: unset;
}

.facility-table__popover {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.facility-table__popover .sample-point__popover {
}
.facility-table__popover .address__popover {
}
.ant-modal-content {
  /* position: sticky; */
  position: fixed;
  /* top: 25vh; */
  /* left: 15vw; */
  /* right: 15vw; */
  /* width: 40vw;
  height: 70vh; */
  /* bottom: 15vh; */
  height: 33rem;
  width: 30rem;
  border-radius: 5px;
  -webkit-box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 7px 20px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.ant-modal-close {
  display: none;
}

.ant-modal-body {
  height: 100%;
  width: 100%;
}

.ant-select-item {
  font-size: 0.8em;
  padding: 2px 8px;
}

.ant-select-item.ant-select-item-option:not(:first-child) {
  font-weight: normal;
}

.facility-header__main .ant-table {
  font-size: 12px !important;
}

.ant-table-body {
  overflow: unset;
  /* overflow-y: scroll; */
}
.step-form .ant-table-body {
  /* overflow: unset !important; */
  overflow-x: hidden;
  overflow-y: scroll;
}

.facility-details__layout .ant-table-body {
  overflow: unset !important;
}

.facility-table .ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  /* padding-bottom: 22px;
  padding-top: 22px; */
}
.facility-overflow__menu .menu-divider {
  padding: unset;
  background: var(--dark);
}
.facility-overflow__menu .ant-divider-horizontal {
  margin: 10px 0;
  color: var(--dark);
  /* background: var(--dark); */
  width: 100%;
}
