.SideNav {
  overflow: unset !important;
}
.main-panel {
  overflow: unset !important;
}
body {
  overflow: unset !important;
}
body
  > div:nth-child(10)
  > div
  > div.ant-modal-wrap
  > div
  > div.ant-modal-content
  > div
  > div
  > div.ant-row.ant-row-start.ant-row-middle
  > div
  > div:nth-child(2)
  > div:nth-child(2) {
  overflow-x: hidden !important;
}

body > div:nth-child(13) > div > div.ant-modal-wrap > div > div.ant-modal-content {
  overflow: hidden !important;
}
.ant-modal-content {
  overflow-x: unset;
  overflow: hidden;
}
