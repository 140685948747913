.lga__lookup {
  padding-left: 2rem;
  font-family: var(--font-family);
  color: #2e384d;
}

.lga__lookup__row td:nth-child(4) {
  text-align: right !important;
}
/* CREATE LGA MODAL */
.create__lga__modal {
  height: 350px !important;
  border-radius: 10px !important;
  font-family: var(--font-family);
}
.create__lga__modal .ant-modal-content {
  height: inherit;
  border-radius: 10px !important;
}

.create__lga__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}

.create__lga__modal .ant-form-item-required {
  /* font-weight: 600; */
}
.create__lga__modal .ant-form-item-required::before {
  display: none !important;
}

.create__lga__modal .ant-form-item-explain {
  margin-top: 0;
}
.edit__lga__modal {
  width: 600px !important;
  height: 350px !important;
  font-family: var(--font-family);
  border-radius: 10px;
  background: #fff;
}
.pageheader__start {
  justify-content: flex-start;
}
.edit__lga__modal .ant-modal-content {
  width: inherit;
  height: inherit;
  border-radius: 10px;
}
.edit__lga__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.align-center {
  align-items: center;
}

.edit__Lg-form .ant-form-item-label label {
  /* font-weight: 600; */
}
.edit__Lg-form .ant-form-item-required:before {
  display: none !important;
}

.edit__Lg-form .ant-form-item-explain {
  margin-top: 0 !important;
}

.create__lga__modal .ant-modal-content,
.edit__lga__modal .ant-modal-content {
  width: 700px;
  border-radius: 5px !important;
  /* height: 400px; */
  height: fit-content;
}

.lga__lookup__form .ant-form-item,
.edit__Lg-form .ant-form-item {
  margin-bottom: unset;
}

.lga__lookup__form .form-controlxx.ant-select,
.edit__Lg-form .form-controlxx.ant-select {
  margin-bottom: unset;
}

.lga__lookup__form input,
.edit__Lg-form input {
  margin-bottom: unset !important;
}

.lga__lookup__form .ant-form-item-explain,
.edit__Lg-form .ant-form-item-explain {
  text-align: left;
}
