@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed|Montserrat');

div,
label,
p,
li,
strong {
  /* font-family: var(--font-family); */
}

.facility-overview ul {
  padding: 0;
  list-style: none;
}

.facility-overview li {
  padding-bottom: 1%;
}

.facility-overview li:focus {
  outline: none;
  color: #780b1c;
  font-weight: bold;
}

.facility-overview a {
  text-decoration: none;
}

.facility-overview .app {
  height: 50vh;
  width: 100%;
  /* height: 100vh;
  width: 100vw; */
}

.facility-overview #map {
  height: 100%;
}

.facility-overview .filter-button {
  display: none;
  position: absolute;
  text-align: center;
  font-size: 1em;
  color: #fff;
  margin-left: 3vw;
  margin-top: 3vh;
  padding: 1%;
  border: 1px solid #fff;
  background-color: #731725;
  z-index: 3;
}

.facility-overview .listings-container {
  display: none;
  position: absolute;
  /* width: 25vw; */
  /* min-width: 180px; */
  /* margin-left: 3vw; */
  /* margin-top: 10vh; */
  width: 15vw;
  min-width: 100px;
  max-height: 50vh;
  overflow-y: scroll;
  margin-left: 1vw;
  margin-top: 5vh;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 1;
  padding: 2%;
}

.facility-overview .display {
  display: block;
}

.facility-overview .info-box {
  position: absolute;
  z-index: 2;
  width: 30%;
  min-height: 40%;
  margin-right: 3%;
  margin-top: 3%;
  border: 1px solid black;
}

.facility-overview .close-box {
  display: block;
  width: 100%;
  border: none;
  background-color: #fff;
  float: right;
  text-decoration: none;
  color: #731725;
  font-size: 1rem;
  cursor: pointer;
  text-align: right;
}

.facility-overview .info-box-message {
  text-align: justify;
}

.facility-overview .img-container {
  width: 100%;
  margin: auto;
}

.facility-overview .pub-img {
  width: 100%;
  border: 1px solid #000;
}

.facility-overview .overview-box {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #591934;
  border: none;
  padding: 0;
  z-index: 5;
  text-align: center;
  display: table;
  opacity: 0.92;
}

.facility-overview.overview-title {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 5em;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  opacity: 1;
  text-align: center;
}

.facility-overview.display-none {
  display: none;
}

.facility-overview.filtered-pubs-titles {
  color: #731725;
}

.facility-overview.hint {
  font-size: 0.7em;
  font-style: italic;
}

.facility-details__layout section#pubfilter {
  max-height: 45vh;
}
.facility-details__layout section#pubfilter .listing-results {
  max-height: 27vh;
  overflow: scroll;
}

@media only screen and (max-width: 600px) {
  .facility-overview input {
    border: 1px solid #a1a1a1;
  }

  .facility-overview .info-box {
    width: 50%;
  }

  .facility-overview.close-info-box {
    background-color: #fff;
  }
}
