.admin__reports {
  padding: 2rem 0 2rem 2rem;
  font-family: var(--font-family);
  color: var(--dark);
}

.compliance__report__content .trv-content.k-splitter .k-pane.trv-document-map-splitter {
  width: 100% !important;
  transition: all ease-in-out;
}
.compliance__report__content .trv-pages-area.k-widget.k-pane.printpreview {
  width: 100% !important;
  transition: all ease-in-out;
}

.admin__reports .ant-tabs-content {
  height: fit-content;
  /* width: 300px; */
  /* width: max-content; */
  min-height: 900px;
}

.admin__reports .ant-tabs-tabpane.ant-tabs-tabpane-active {
  transition: visibility ease;
}

.admin__reports.generated .ant-tabs-content {
  min-height: unset;
}

.ant-tabs.audit__tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left !important;
  font-size: 16px;
  margin-right: 1rem;
}

.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--dark);
  font-family: var(--font-family-bold);
  background: #dff1ff;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab:nth-child(1).ant-tabs-tab-active {
  background: #fff;
}
.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--sidebar-bg);
}

.ant-tabs.audit__tabs .ant-tabs-ink-bar {
  background-color: var(--sidebar-bg) !important;
  display: none !important;
}
.ant-tabs.audit__tabs .ant-tabs-nav .ant-tabs-tab {
  border-bottom: 1px solid #f9f9f9;
}
.admin__tab {
  height: calc(100vh - 85px);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in;
}

.compliance__date__filter {
  border: none !important;
}
.compliance__filter {
  width: 70%;
}
.compliance__filter .ant-select-selector {
  border: none !important;
}
.compliance__filter.ant-select-focused.ant-select-single.ant-select-selector {
  box-shadow: none !important;
  outline: none;
}
.compliance__filter .ant-select-arrow {
  left: 0;
}
.compliance__divider {
  height: 1.5rem !important;
  color: #979797 !important;
}

.compliance__report__content {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transition: all ease-in-out;
  /* margin-top: 0px !important; */
  /* padding-top: 0px !important; */
}

.compliance__report__content .audit__summary {
  margin-top: 0px !important;
}

.compliance__report__content .trv-report-viewer {
  position: absolute;
  transition: all ease-in-out;
}
.compliance__report__content,
.audit__log__content,
.noncompliance__report__content,
.emission__stat__content,
.density__grid__content {
  box-shadow: 0 7px 13px 0 rgba(149, 156, 182, 0.15);
  min-height: calc(100vh - 120px);
  border: solid 0.9px #eaebf0;
  /* padding: 2rem; */
}

/* AUDIT LOG */
.audit__log__content .logo__wrap img,
.compliance__report__content .logo__wrap img,
.noncompliance__report__content .logo__wrap img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.audit__summary p span {
  margin-right: 3px;
}
