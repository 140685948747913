.config__container {
    background: #f9f9f9;
    font-family: var(--font-family);
    padding-top: 1rem;
    min-height: calc(100vh - 70px);
}

.ant-tabs.config__tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left !important;
    font-size: 16px;
    margin-right: 1rem;
}
.ant-tabs.config__tabs .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--dark);
    font-family: var(--font-family);
    background: #f3f7fb;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.ant-tabs.config__tabs .ant-tabs-nav .ant-tabs-tab:hover {
    color: var(--sidebar-bg);
}

.ant-tabs.config__tabs .ant-tabs-ink-bar {
    background-color: var(--sidebar-bg) !important;
    display: none !important;
}

.parameters__container,
.measurement__container {
    min-height: calc(100vh - 70px);
}

.unit__btn {
    background: transparent;
    border: none !important;
    padding: 0;
    height: 20px;
}
.unit__btn img {
    width: 20px;
    /* border: 1px solid; */
    height: 20px;
}
.unit__popover {
    background: #fff;
    width: 500px;
    height: 150px;
}
.unit__popover .ant-popover-content {
    width: inherit;
    height: 150px;
    overflow: hidden;
    box-shadow: none;
}
.unit__popover .ant-popover-inner-content {
    box-shadow: none !important;
}
.unit__popover .ant-popover-inner {
    box-shadow: none;
}
.unit__popover .ant-popover-arrow {
    bottom: -4.8px !important;
}
.transparent__bg {
    background: transparent;
}

.notification__form {
    font-family: var(--font-family);
}

.notification__form .ant-form-item-label label {
    font-weight: 600;
    font-family: var(--font-family-bold);
    font-size: 16px;
}

.config__switch.ant-switch-checked {
    background: var(--sidebar-bg) !important;
}
.config__switch.ant-switch {
    background: #fff;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
}

.config__switch .ant-switch::after {
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.config__checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--sidebar-bg);
    border-color: var(--sidebar-bg) !important;
}
.config__units {
    font-size: 16px !important;
}

.config__units .ant-radio-checked .ant-radio-inner {
    border-color: var(--sidebar-bg);
    background-color: var(--sidebar-bg);
}
.config__units .ant-radio-inner::after {
    background-color: #fff;
}

.ant-collapse.custom__collapse,
.custom__collapse__panel {
    background: transparent !important;
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
}
.ant-collapse.custom__collapse .ant-collapse-header {
    font-family: var(--font-family-bold);
    text-transform: uppercase;
}

/* MODAL */

.add__measurement__modal {
    background: #fff;
    font-family: var(--font-family);
    height: 300px;
    width: 600px;
    border-radius: 13px;
}
.add__measurement__modal .ant-modal-content {
    border-radius: 13px;
    width: inherit;
    height: inherit;
    background: #fff;
}
.add__measurement__modal .ant-modal-title {
    font-family: var(--font-family-bold);
    font-size: 18px;
}
.add__measurement__modal .ant-form-item-label label,
.add__parameter__modal .ant-form-item-label label {
    font-weight: 600;
}

.add__parameter__modal {
    font-family: var(--font-family);
    border-radius: 13px;
    min-height: 300px;
    width: 800px !important;
}
.add__parameter__modal .ant-modal-content {
    height: inherit;
    width: inherit;
    border-radius: 13px;
}
.add__parameter__modal .parameter__input .ant-input-number {
    width: 100%;
    border-radius: 5px;
}
.add__parameter__modal .ant-modal-title,
.edit__measurement__modal .ant-modal-title,
.edit__parameter__modal .ant-modal-title {
    font-family: var(--font-family-bold);
}
.edit__measurement__modal {
    font-family: var(--font-family);
    border-radius: 13px;
    height: 300px;
}
.edit__measurement__modal .ant-modal-content {
    border-radius: 13px;
    height: inherit;
    width: inherit;
}

.parameter__table td:nth-child(5) {
    text-align: right;
}

.config__switch.ant-switch-disabled {
    opacity: 0.3;
}
.config__switch.config__switch.ant-switch-disabled + .switch__text {
    opacity: 0.3;
}
.add__params__btn {
    transform: translateY(-47px);
}
.measurements__row {
}

/* EDIT PARAMETERS */
.edit__parameter__modal {
    font-family: var(--font-family);
    border-radius: 13px;
    min-height: 300px;
    width: 800px !important;
}
.edit__parameter__modal .ant-modal-content {
    width: inherit;
    height: inherit;
    border-radius: 13px;
}
.edit__parameter__form .ant-form-item-label {
    font-weight: 600;
}
.edit__parameter__form .ant-input-number.parameter__input {
    width: 100%;
    border-radius: 5px;
}
.edit__parameter__form .ant-form-item-explain {
    margin-top: 0;
}
.breadcrumb__container {
    padding-bottom: 2rem;
}
.breadcrumb__container .ant-breadcrumb .ant-breadcrumb-link a {
    color: #0c5bab !important;
}

.ant-table-wrapper.measurement__table .ant-table-thead > tr > th {
    background: #f3f7fb !important;
}
