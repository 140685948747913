.dashboard-content {
  display: grid;
  column-gap: 1rem;
  /* row-gap: 1.5rem; */
  grid-auto-columns: 1fr;
  grid-template-columns: minmax(500px, 1fr) minmax(600px, 1.1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
}
@media (max-width: 1469px) {
  .dashboard-content {
    grid-template-columns: minmax(400px, 1fr) minmax(500px, 1.1fr);
  }
}

.dashbaord-dropdown-menu {
  max-height: 25rem;
  width: 10rem;
  overflow-y: scroll;
}

.dashboard-map__component {
  /* grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 2; */
  grid-row: auto / span 2;
}
.dashboard-content .dashboard-content-box {
  box-shadow: 0 23px 45px 0 rgba(0, 0, 0, 0.04);
}

@media (max-width: 1300px) {
  .dashboard-content {
    grid-template-columns: minmax(400px, 1fr) minmax(500px, 1.1fr);
  }
}
@media (max-width: 1200px) {
  .dashboard-content {
    grid-template-columns: minmax(500px, 1fr);
  }
  .dashboard-map__component {
    grid-row: auto / span 1;
  }
}

.dashboard-summary,
.dashboard-content {
  padding: 24px 30px;
  font-family: var(--font-family);
}

.dashboard-content {
  padding-top: 0px;
}

.dashboard__summary-col.ant-col {
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 15rem;
  max-width: 15rem;
  height: 120px;
  /* width: 200px;
  max-width: 200px;
  height: 120px; */
  box-shadow: 3px 5px 3px -4px rgba(67, 90, 111, 0.2);
  /* box-shadow: -4px -2px 6px -2px rgba(67, 90, 111, 0.5); */
  /* box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7); */
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #f9f4f9;
  overflow: hidden;
  flex: unset;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dashboard__summary-col.ant-col .dashboard-summary-box {
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 5px;
}

.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-stat {
  font-family: var(--font-family-bold);
  font-size: 23px;
}
.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label {
  margin-top: 10px;
  padding-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--dark);
  opacity: 0.6;
}

.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label .box-item-view {
  position: absolute;
  right: 8px;
  bottom: 2px;
  opacity: 0.4;
  cursor: pointer;
}
.dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label .box-item-view:hover {
  opacity: 1;
}

.dashboard-content-box {
  margin-bottom: 30px;
}

@media (max-width: 1469px) {
  .dashboard__summary-col.ant-col {
    width: 12rem;
    max-width: 12rem;
  }
}

@media (max-width: 1269px) {
  .dashboard__summary-col.ant-col {
    display: flex;
    flex-flow: column;
    width: 11rem;
    max-width: 11rem;
    height: 6.5rem;
  }
  .dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label {
    font-size: 12px;
  }
}

@media (max-width: 1150px) {
  .dashboard__summary-col.ant-col {
    width: 9rem;
    max-width: 9rem;
    height: 6rem;
  }
}

@media (max-width: 960) {
  .dashboard__summary-col.ant-col {
    display: flex;
    flex-flow: column;
    width: 8rem;
    max-width: 8rem;
    height: 6rem;
    margin-bottom: 10px;
  }
  .dashboard__summary-col.ant-col .dashboard-summary-box.box-item-label {
    font-size: 10px;
  }
}

.dashboard-content-box rect {
  fill: steelblue;
}

.dashboard-content-box circle {
  fill: steelblue;
}

.dashboard-content-box .tick text {
  fill: #635f5d;
}

.dashboard-content-box .tick line {
  stroke: #c0c0bb;
}

.dashboard-content-box .axis-label {
  font-size: 12px;
  fill: #8e8883;
}

.dashboard-content-box .title {
  font-size: 1em;
  fill: #636363;
  text-anchor: middle;
}

.dashboard-content-box .line-path {
  fill: none;
  stroke: steelblue;
  stroke-width: 2;
  stroke-linejoin: round;
}

.dashboard-content-box text {
  /* text-anchor: middle; */
  font-size: 12px;
  font-family: var(--font-family);
}

.dashboard-content-box .sphere {
  fill: #4242e4;
}

.filter-popover.filter-pollution-violations .ant-popover-content {
  width: 150px !important;
  overflow-x: hidden;
}
.filter-popover.dashboard-map-filter .ant-popover-content {
  width: 150px !important;
  overflow-x: hidden;
}

.divide-under {
  border-color: rgba(0, 0, 0, 0.0005);
  border-bottom: 1px solid;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.limit-violations-canvas .tick-xscale text {
  font-size: 9px;
  transform: rotate(-45deg);
  /* width: 2px;
  text-overflow: clip; */
}

.violations-trend-canvas .y-axis line {
  opacity: 0.2;
}
.violations-trend-canvas .y-axis path {
  display: none;
}
.violations-trend-canvas .line {
  fill: none;
  stroke-width: 1.5px;
  opacity: 0.75;
}
.violations-trend-canvas .overlay {
  fill: none;
  pointer-events: all;
}
.violations-trend-canvas .lineHoverText {
  text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
}
.violations-trend-canvas .hoverCircle {
  opacity: 0.75;
}

.dashboard-violations__trend .violation-trend-controls {
  width: 9rem;
  display: inline-flex;
  justify-content: space-between;
  font-family: var(--font-family);
  height: 20px;
  align-items: baseline;
}

.violation-trend-controls #selectbox {
  width: 8em;
  font-size: 10px;
  font-family: var(--font-family);
  margin-right: 5px;
  cursor: pointer;
}

.dashboard-map-controls #selectbox {
  width: 20em;
  font-size: 16px;
  font-family: var(--font-family);
  margin-right: 5px;
  cursor: pointer;
  border: none;
}

.dashboard-content-box .filter-popover .ant-dropdown-link {
  font-size: 16px !important;
  font-family: var(--font-family) !important;
}

.limit-violators-canvas .tick text {
  font-size: 9px;
  font-weight: normal;
  font-family: var(--font-family);
  /* font-size: 7.5px; */
}
