.edit__role__modal {
  width: 950px !important;
  height: 850px !important;
  border-radius: 5px;
  font-family: var(--font-family);
  margin-bottom: 3rem;
}
.edit__role__modal .ant-modal-content {
  height: inherit;
  width: 950px !important;
  position: sticky !important;
  border-radius: 5px;
  transform: translateY(-4rem);
  backface-visibility: hidden;
}

@media screen and (max-width: 1469px) {
  .edit__role__modal .ant-modal-content {
    height: 700px;
    width: 950px !important;
    transform: translateY(-5rem);
    backface-visibility: hidden;
  }
}

@media screen and (max-width: 1269px) {
  .edit__role__modal .ant-modal-content {
    height: 650px;
    width: 950px !important;
    transform: translateY(-5.5rem);
    backface-visibility: hidden;
  }
}

.edit__role__modal .ant-modal-body {
  height: calc(100% - 114px);
  max-height: calc(100% - 114px);
}
.edit__role__modal .edit__role__content {
  padding: 0 38px;
}
.edit__role__header h3 {
  font-family: var(--font-family-bold);
  color: #4a4a4a;
  line-height: 1;
}
.edit__role__header p {
  font-size: 18px;
  font-weight: normal;
}
.edit__role__modal .ant-modal-title {
  font-weight: 600;
  /* font-family: var(--font-family-bold); */
  padding: 13px 38px;
}
.edit__role__modal .ant-form-item-label {
  font-weight: 600;
}

.edit__role__modal .edit__role-form .ant-form-item {
  margin-bottom: 10px;
}

.edit__role-form .form-group.buttons {
  margin-top: 2rem;
}

.permissions-container {
  height: 350px;
  /* display: flex;
  flex: 1;
  flex-direction: column; */
  width: 100%;
  overflow: auto;
  display: inline-grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

@media screen and (max-width: 1469px) {
  .permissions-container {
    height: 250px;
  }
}
@media screen and (max-width: 1269px) {
  .permissions-container {
    height: 200px;
  }
}

@keyframes blink {
  78% {
    transform: scale(1.5);
  }

  92% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.loading-permissions span:nth-child(1) {
  animation: blink linear infinite 1s;
}
.loading-permissions span:nth-child(2) {
  animation: blink linear infinite 2s;
}
.loading-permissions span:nth-child(3) {
  animation: blink linear infinite 3s;
}

.edit__role__modal .permission-name {
  text-transform: capitalize;
  margin-bottom: 20px !important;
  font-size: 16px;
}
.edit__role-form .ant-checkbox-group-item + .ant-checkbox-group-item {
  display: block;
}
.edit__role-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
}
.edit__role-form .ant-checkbox-wrapper {
  margin-bottom: 17px;
  display: block;
}
.edit__role-form .ant-checkbox-wrapper > span {
  color: #828ea9;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 12px;
}
.edit__role-form .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.edit__role__modal .permissions-container h3 {
  margin: 1rem 0;
  font-weight: bold;
}
.edit__role-form .ant-form-item-explain {
  margin-top: 0 !important;
}
.edit__role-form .error-text {
  font-size: 16px;
  text-align: center;
}
.edit__role-form .ant-form-item-required::before {
  display: none !important;
}
.edit__role-form .ant-checkbox-wrapper .ant-form label {
  font-size: 13px;
}
.edit__role-form .ant-form-item-label label::before {
  display: none;
}
.edit__role-form input {
  border: 1px solid rgba(216, 216, 216, 0.1);
  height: 41px;
  background: rgba(216, 216, 216, 0.2);
}
.edit__role-form input:focus {
  border-color: #0052cc;
}
.edit__role-form input::placeholder {
  color: #d8d8d8;
}
