.forgot__password-form {
  font-family: var(--font-family);
}

.forgot__password-form .form-header {
  font-family: var(--font-family);
  margin-bottom: 37px;
}

.forgot__password-form .form-header h1 {
  font-family: var(--font-family-bold);
}

.forgot__password-form .error-message {
  color: red;
  font-weight: 700;
  font-size: 12px;
}
.forgot__password-form .ant-form-item-required {
  font-size: 14px;
  font-weight: 600;
}
.forgot__password-form .ant-form-item-required::before {
  display: none !important;
}
.forgot__password-form .email-input > input {
  border: 1px solid #c3cad9;
  height: 50px;
}
.forgot__password-form .email-input > input:focus {
  border: 1px solid #0c5bab;
}
.forgot__password-input {
  height: 41px;
}
.forgot__password-form button {
  width: 100%;
  border-radius: 8px;
}
.forgot__password-form a {
  color: #0c5bab !important;
}
.forgot__password-form a > i {
  margin-right: 4px;
}
.forgot__password-form .loader-container {
  text-align: center;
}

/* .forgotpassword__confirm-modal {
    background: #fff;
    width: 600px !important;
    height: 500px;
    font-family: "Akkurat";
}
.forgotpassword__confirm-modal .ant-modal-content {
    height: inherit;
    width: inherit;
    position: sticky !important;
}
.forgotpassword__confirm-modal .ant-modal-footer {
    border-top: none;
}

.forgotpassword__confirm-modal div {
    text-align: center;
}

.forgotpassword__confirm-modal h3 {
    font-size: 18px;
    margin-top: 2rem !important;
}
.forgotpassword__confirm-modal p {
    color: #828ea9;
    font-size: 14px;
    margin-top: 2rem !important;
    font-weight: 500;
    margin-bottom: 2rem !important;
}
.forgotpassword__confirm-modal .message {
    text-transform: capitalize;
}
.forgotpassword__confirm-modal img {
    width: 200px;
}
.forgotpassword__confirm-modal .footer {
    width: 100%;
    text-align: center;
} */
.text-right {
  text-align: right;
}
