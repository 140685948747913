#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}

.facility-details__layout.ant-layout {
  background: unset;
}
.facility-details__layout {
  background: #fff;
}

.ant-layout {
  background: unset;
}

.facility-details__layout aside.ant-layout-sider {
  height: 34.5rem !important;
}

.facility-details__layout .ant-layout-sider-children > ul.ant-menu {
  height: calc(31rem - 55px);
  /* height: calc(31rem - 48px); */
  overflow-y: scroll;
}
.facility-details__layout section {
  height: 31.6rem;
  overflow: hidden;
}
.facility-details__layout main.ant-layout-content {
  overflow-y: scroll !important;
  background-color: white;
  margin: 0px !important;
  width: 100%;
}

.facility-details__layout main.ant-layout-content .site-layout-background {
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.facility-view__reports .compare-accordion {
  width: 48rem;
  height: 30rem;
  overflow: unset;
}

.facility-view__reports .compare-accordion .ant-collapse-header {
  text-align: left;
}

.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content-box
  div.ant-row {
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content-box
  .comparison-table
  div.ant-row:nth-child(2) {
  justify-content: space-between;
  flex-wrap: nowrap;
}

.compare-accordion.facility-view__accordion
  .comparison-table
  .ant-table-wrapper
  .ant-table
  .ant-table-container {
  width: 40rem;
  overflow: scroll;
}
.compare-accordion.facility-view__accordion
  .comparison-table
  .ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-header {
  /* width: 40rem !important; */
  overflow: unset !important;
}

.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content
  .switch-average-type {
  display: flex;
  flex-flow: column;
  align-self: flex-start;
  justify-content: space-between;
  margin-top: 2rem;
  text-align: -webkit-left;
  text-align: left;
  font-size: 12px;
  /* align-items: center; */
}

.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content
  .switch-average-type
  p {
  width: 5rem;
  font-size: 12px;
}
.compare-accordion.facility-view__accordion
  .ant-collapse-item
  .ant-collapse-content
  .switch-average-type
  .ant-radio-group
  label.ant-radio-wrapper {
  font-size: 12px;
}
