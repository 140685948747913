.edit__zone__btn {
  background: transparent !important;
}
.edit__zone__modal {
  font-family: var(--font-family);
  color: #2e384d;
  height: 300px !important;
}
.edit__zone__modal .ant-modal-content {
  width: inherit;
  height: inherit;
}
.edit__zone__modal .ant-modal-title {
  font-weight: 600;
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.edit__zone__modal .add-more-container {
  margin-bottom: 1rem;
}
.edit__zone__form .save-btn {
  border: none;
  background: #0c5bab;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  margin-right: 10px;
  margin-top: 1.5rem;
}
.edit__zone__form .cancel-btn {
  color: #0c5bab;
  border: none;
  background: transparent;
  padding: 0.5rem 1.5rem;
  margin-top: 1.5rem;
}
.edit__zone__form .ant-form-item-required::before {
  display: none !important;
}
.edit__zone__form .ant-form-item-label label {
  font-weight: 600;
  font-size: 14px;
}
.edit__zone__form .ant-form-item-label label:before {
  display: none;
}

.edit__zone__form .ant-form-item-explain {
  margin-top: 0;
}

.editable-row td:nth-child(3) {
  text-align: right;
}
.no-padding-x {
  padding: 24px 0 !important;
  padding-top: 0 !important;
}
.flex-column-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.lookup__title {
  padding-left: 30px !important;
}
.add__zone__modal {
  font-family: var(--font-family);
  border-radius: 5px;
  height: 300px !important;
}
.add__zone__modal .ant-modal-content {
  border-radius: 5px;
  height: inherit;
}
.add__zone__modal .ant-modal-title {
  font-family: var(--font-family-bold);
  font-size: 18px;
}
.add__zone__modal .ant-form-item-required {
  font-weight: 600;
}

.add__zone__modal .ant-form-item-required::before {
  display: none !important;
}
