/* FONT FACE DECLARATION */
@font-face {
  font-family: 'Averta';
  font-weight: 'normal';
  src: url('https://cdn.lubred.net/fonts/AvertaRegular.otf') format('opentype');
}

@font-face {
  font-family: 'Averta Bold';
  font-weight: 'bold';
  src: url('https://cdn.lubred.net/fonts/AvertaBold.otf') format('opentype');
}

/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap');

/* Variables */
:root {
  /* Primary Colors */
  --button-primary: #0c5bab;
  --primary: #0072c3;
  --light: #f5f7ff;
  --dark: #2e384d;
  --danger: #fb4b53;
  --white: #ffffff;
  --sidebar-bg: #0c5bab;
  --disable-border: #d9d9d9;
  --disable-bg: #f5f5f5;
  --no-cursor-card: #e0e0e0;
  --disable-color: rgba(0, 0, 0, 0.25);
  --gray: #828ea9;
  --active-green: #00ab44;
  --inactive-red: #eb0a4ddc;
  --result-title: #2e384d;
  --result-subtitle: #828ea9;
  --error-red: red;
  --blue: #2e5bff;
  --menu-toggle-color: var(--primary);

  /* Fonts */
  --font-family: 'Averta', sans-serif;
  --font-family-bold: 'Averta Bold', sans-serif;

  /* Componet Font Sizing */
  --form-label-fs: 14px;
  --form-label-fw: 500px;

  /* Main Layout */
  --sidebar-main-w: 204px;
  --sidebar-min-w: 64px;

  /* Spacing */
  --input-mb: 0.75rem;
  --form-label-mb: 5px;
  --form-label-fs: 14px;
  --sidebar-min-padding-h: 10px;
  --sidebar-min-padding-w: 20px;

  --sidebar-min-padding: var(--sidebar-min-padding-h, --sidebar-min-padding-w);

  /* Components Colors */
  /* --qaqc-highlight: #cbdfb8; */
  --qaqc-highlight: #ebfbdc;
  --non-qaqc-highlight: #ffffff;
  /* --comparing-report-header-color: #699ad0; */
  --comparing-report-header-color: #bfdcfb;
  --comparing-report-header-cell-color: #03382b;
  /* --comparing-report-header-cell-color: #e7ec68; */
  --compare-table-cell-color: var(--dark);
  --compare-table-head-color: #03382b;
  --compare-table-head-bg: #699ad0;
  --regular-table-header-bg: #e2f3f9;
}

/* DEFAULT BASE STYLES */
/* * {
  font-family: Averta, sans-serif;
} */
html {
  font-size: 16px;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--white);
  font-family: var(--font-family), sans-serif;
  color: var(--dark);
  font-size: 14px;
  height: 100%;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

button {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

/* FONT SIZES */
.heading {
  font-size: 23px;
  font-weight: bold;
  font-family: var(--font-family-bold);
}

.subheading,
.sub-heading {
  font-size: 16px;
}

.Headers {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}
.Sub-Headers {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
}

.small-text {
  font-size: 14px;
}

/* FONT WEIGHT */
.bold {
  font-weight: bold;
}

.inline {
  display: inline;
}

/* COLORS */
.text-white {
  color: var(--white);
}
.bg-white {
  background-color: var(--white);
}
.text-dark {
  color: var(--dark);
}
.bg-dark {
  background-color: var(--dark);
}
.text-light {
  color: var(--light);
}
.bg-light {
  background-color: var(--light);
}
.text-primary {
  color: var(--primary);
}
.bg-primary {
  background-color: var(--primary);
}
.text-danger {
  color: var(--danger) !important;
}
.bg-danger {
  background-color: var(--danger);
}
.text-gray {
  color: var(--gray);
}
.text-blue {
  color: var(--blue) !important;
}
.text-success {
  color: var(--active-green);
}
.text-warning {
  color: #f7b500;
}

/* SPACING */
/* Margin */
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mb-1rem {
  margin-bottom: 1rem !important;
}
.mb-2rem {
  margin-bottom: 2rem !important;
}
.mt-1rem {
  margin-top: 1rem !important;
}
.mt-2rem {
  margin-top: 2rem !important;
}
.ml-1rem {
  margin-left: 1rem !important;
}
.ml-2rem {
  margin-left: 2rem !important;
}
.mr-1rem {
  margin-right: 1rem !important;
}
.mr-2rem {
  margin-right: 2rem !important;
}

/* Padding */
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}

.pb-1rem {
  padding-bottom: 1rem;
}
.pb-2rem {
  padding-bottom: 2rem;
}
.pt-1rem {
  padding-top: 1rem;
}
.pt-2rem {
  padding-top: 2rem;
}
.pl-1rem {
  padding-left: 1rem;
}
.pl-2rem {
  padding-left: 2rem;
}
.pr-1rem {
  padding-right: 1rem;
}
.pr-2rem {
  padding-right: 2rem;
}

/* DISPLAY */
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}

/* FLEX */
.flex-grow {
  flex-grow: 1;
}

/* Browser default resets */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 0 !important;
}

/* Buttons */
button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

button:hover {
  opacity: 0.8;
}

.text-center {
  text-align: center;
}
