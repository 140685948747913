@import "../../../styles/index.css";

.schedule__container {
  padding: 2rem;
}
.schedule__card {
  border-radius: 5px;
  box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
  border: 1px solid;
  /* background-color: #ffffff; */
  cursor: pointer;
  padding: 1.5rem;
  font-family: var(--font-family);
  border: 0.5px solid #2e5bff;
  background: #f3f7fb;
}
.schedule__card.schedule__card__inactive {
  /* opacity: 0.5 !important; */
  background: #f3f7fb;
  border: none;
  box-shadow: none;
}
.schedule__card.schedule__card__inactive h3 {
  color: #000;
  /* opacity: 0.5; */
}
.schedule__card.schedule__card__inactive .schedule__info__badge {
  /* opacity: 0.3; */
  color: #2e5bff;

  /* background-color: rgba(192, 195, 204, 0.9); */
  /* background: rgba(224, 224, 224, 0.5); */
  background-color: rgba(12, 71, 244, 0.1);
}
.schedule__card.schedule__card__inactive h3 {
  font-weight: bold;
}
.schedule__card.schedule__card__inactive .schedule__icon {
  /* filter: contrast(0.5) saturate(0.5) brightness(0.9) grayscale(1); */
}
.schedule__icon {
  width: 32px;
  height: 35px;
  margin-right: 10px;
}
.schedule__heading h3 {
  font-size: 16px;
  font-family: var(--font-family-bold);
}
.schedule__heading p {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 600;
}
.schedule__info__badge {
  display: inline-flex;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 20px;
  background-color: rgba(12, 71, 244, 0.1);
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 2.5rem;
  font-weight: 600;
  text-align: center;
  color: #2e5bff;
}
.schedule__content {
  /* margin-top: 5rem; */
  font-family: var(--font-family);
}
.schedule__content .ant-card {
  border: none;
}
.schedule__content .ant-card-head {
  padding: 0;
}
.schedule__content .ant-tabs-nav {
  font-size: 14px;
}
.schedule__content .ant-tabs-nav .ant-tabs-tab-active {
  color: #0c47f4;
  font-weight: bold;
}
.schedule__content .ant-tabs-tab {
  color: #9b9b9b;
}
.schedule__content .ant-tabs-top .ant-tabs-ink-bar-animated {
  background-color: #0c47f4;
}
.schedule__content .ant-card-body {
  padding: 24px 0;
}
.schedule__filter {
  width: 450px;
  align-items: center;
  padding: 0 10px;
}
.schedule__filter .state__filter {
  margin-left: 10px;
}

/* .schedule__filter .ant-select {
    box-shadow: inset 0 -1px 0 0 #edf0f2;
    background-color: #fafafa;
} */

/* .unscheduled__table .ant-table-thead th,
.quarter__table .ant-table-thead th {
    font-size: 14px !important;
    font-family: var(--font-family);
} */
.unscheduled__table .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner,
.quarter__table .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
  background: #0788de;
  border-color: #0788de;
}
.unscheduled__table .ant-table-thead th .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.quarter__table .ant-table-thead th .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #0788de;
}
.quarter__table .ant-table-thead tr > th {
  background: #f3f7fb;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.unlock__btn {
  color: #fa6400 !important;
  background-color: rgba(250, 100, 0, 0.1) !important;
  border-radius: 25px !important;
  border: none;
  height: 29px;
  line-height: 29px;
  word-break: break-all;
  overflow: hidden;
  padding: 0 0.5rem;
}
.lock__btn {
  color: #2e5bff !important;
  background: rgba(12, 71, 244, 0.1) !important;
  border-radius: 25px !important;
  border: none;
  height: 29px;
  padding: 0 0.5rem;
}
.lock__btn:disabled {
  opacity: 0.3;
  color: #c0c3cc;
  background-color: rgba(12, 71, 244, 0.1);
}

/**** SCHEDULE MODAL ****/
.schedule__modal {
  border-radius: 5px;
}
.schedule__modal .ant-modal-content {
  width: 64rem;
  height: 280px;
  transform: translate(-9rem, -1rem);
  padding: 30px 30px 30px 60px;
}
@media screen and (max-width: 1369px) {
  .schedule__modal .ant-modal-content {
    width: 59rem;
    padding: 24px 24px 24px 30px;
  }
}
@media screen and (max-width: 1269px) {
  .schedule__modal .ant-modal-content {
    width: 57rem;
    padding: 24px 24px 24px 30px;
  }
}
@media screen and (max-width: 1069px) {
  .schedule__modal .ant-modal-content {
    width: 53rem;
    padding: 24px 24px 24px 30px;
  }
}
.schedule__modal .ant-modal-header {
  border: none;
  padding: 1rem 0;
  /* padding: 1.5rem 0 0 3.3rem; */
}
.schedule__modal .ant-modal-body {
  padding: 0;
  height: calc(100% - 54px);
}
.schedule__modal .ant-modal-title {
  font-family: var(--font-family);
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
}

.schedule__modal .lock__btn {
  font-size: 14px;
  width: 150px;
  justify-content: center;
  margin: 1rem auto;
}
.schedule__modal .schedule__card {
  box-shadow: none;
  cursor: pointer;
  border: none;
  padding: 0;
}

.schedule__modal .ant-col:nth-child(4) .schedule__card .schedule__heading {
  border: none;
}
.schedule__modal .ant-modal-title {
  color: #000;
  font-family: var(--font-famil-bold);
}
.divider__container {
  height: 130px;
  display: flex !important;
  align-items: center;
}
.schedule__modal .ant-divider {
  height: 70px;
  border-color: #e1e2e8;
}
.schedule__modal .schedule__card button {
  font-weight: 600;
}

.schedule__modal .schedule__card .schedule__heading:hover button {
  box-shadow: 3px 3px 13px -8px rgba(67, 90, 111, 0.7);
}

@media screen and (max-width: 1300px) {
  .schedule__card {
    margin-bottom: 1rem;
    padding: 1rem 1rem;
    min-width: 200px; /*12.5rem */
    height: 140px; /* 8.5rem */
  }
  .lock__btn,
  .unlock__btn,
  .schedule__info__badge {
    width: max-content;
  }
  .schedule__heading h3 {
    font-size: 14px;
    font-weight: 600;
  }

  .schedule__info__badge {
    height: 30px;
    padding: 0 1.5rem;
  }
}

.schedule-page.up-outlined,
.schedule-page.down-outlined {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.schedule-page.down-outlined {
  flex-flow: column;
}

.schedule-page.down-outlined .down-outlined-icon {
  font-size: 24px;
  margin-left: -1.75rem;
}

.schedule-page.down-outlined .down-outlined-icon:hover,
.schedule-page.down-outlined .down-outlined-icon svg:hover {
  transition: none;
  opacity: 0.5;
  animation: none !important;
  transform: none !important;
}

.schedule-page.up-outlined .up-outlined-icon {
  font-size: 24px;
  opacity: 0.3;
}

.schedule-page.up-outlined .up-outlined-icon:hover {
  opacity: 1;
  transform: scale(1.05) !important;
}
