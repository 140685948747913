.ant-popover {
  padding: 20px 16px;
}
.ant-popover > .ant-popover-content {
  width: 250px;
  height: auto;
  max-height: 250px;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: -2px 2px 20px -7px rgba(0, 0, 0, 0.1);
  font-family: var(--font-family);
}
.box {
  margin-bottom: 9px;
  font-size: 14px;
}
.box.box-hidden {
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
}
.box > .dark {
  color: #000;
  text-transform: capitalize;
  width: 50px;
  margin-right: 15px;
}
.box > .light {
  color: #9b9b9b;
  font-family: var(--font-family);
  /* float: right; */
  /* margin-left: 15px; */
  word-break: break-all;
  white-space: nowrap;
  text-overflow: clip;
}
.role-badge {
  float: right;
  color: #007ace;
  background: #e7f5ff;
  padding: 5px 6px;
  font-family: var(--font-family);
  border-radius: 10px;
  font-size: 12px;
}
.regulator__input.ant-select {
  width: 100%;
}
